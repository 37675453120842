import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Header.css'; // Importing the CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const handleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    const menuCheckbox = document.querySelector('.menu-checkbox');
    menuCheckbox.checked = false; // Close menu when any link is clicked
    setIsOpen(false); // Also close dropdown
  };

  useEffect(() => {
    const menuCheckbox = document.querySelector('.menu-checkbox');
    const menuItems = document.querySelectorAll('nav ul li a');

    const handleClick = () => {
      menuCheckbox.checked = true; // Close menu when any link is clicked
      setIsOpen(false); // Also close dropdown
    };

    menuItems.forEach(item => {
      item.addEventListener('click', handleClick);
    });

    // Cleanup event listeners on component unmount
    return () => {
      menuItems.forEach(item => {
        item.removeEventListener('click', handleClick);
      });
    };
  }, []);

  return (
    <nav>
      <div className="logo"></div>
      <input type="checkbox" id="click" className="menu-checkbox" />
      <label htmlFor="click" className="menu-btn">
        <i className="fas fa-bars" style={{ color: '#FCFCCA' }}></i>
      </label>
      <ul style={{ fontFamily: 'Baskerville' }}>
        <li><Link to="/" onClick={handleLinkClick}>HOME</Link></li>
        <li><a href="google.com" onClick={handleLinkClick}>ABOUT</a></li>
        <li className="dropdown-li">
          <button onClick={handleDropdown} style={{ color: '#FCFCCA' }} className='collections' >
            COLLECTIONS {isOpen ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}
          </button>
          {isOpen && (
            <div className="dropdown-content">
              <Link to="/Rings" onClick={handleLinkClick} style={{ fontSize: '15px' }}>RINGS</Link>
              <Link to="/Earings" onClick={handleLinkClick} style={{ fontSize: '15px' }}>EARRINGS</Link>
              <Link to="/Pendants" onClick={handleLinkClick} style={{ fontSize: '15px' }}>PENDANTS</Link>
              <Link to="/Necklace" onClick={handleLinkClick} style={{ fontSize: '15px' }}>NECKLACE</Link>
              <Link to="/Bracelates" onClick={handleLinkClick} style={{ fontSize: '15px' }}>BRACELATES</Link>
            </div>
          )}
        </li>
        <li><a href="google.com" onClick={handleLinkClick}>CONTACT US</a></li>
      </ul>
    </nav>
  );
}

export default Header;



