import './ProductDetail.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';

const ProductDetail = ({ 
    mainImageUrl,        // Main image prop
    thumbnail1,          // First thumbnail
    thumbnail2,          // Second thumbnail
    heading, 
    onClose, 
    type, 
    purity, 
    goldWeight, 
    caratRate 
}) => {
    // Use the main image as the default and let users switch between thumbnails
    const [mainImage, setMainImage] = useState(mainImageUrl);

    const handleMainImageClick = (image) => {
        setMainImage(image);
    };

    return (
        <div className="product-detail-overlay" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 999 }}>
            <div className="product-detail" style={{ width: '80%', height: '90%', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#FDF8F6', borderRadius: '8px', padding: '20px', display: 'flex' }}>
                <div style={{ overflowY: 'auto', overflowX: 'hidden', scrollbarWidth: 'none', flex: 1 }}>
                    <section className="py-5">
                        <div className="container" style={{ padding: '0px', margin: '0px' }}>
                            <div className="row gx-5">
                                <aside className="col-lg-6">
                                    <div className="border rounded-4 mb-3 d-flex justify-content-center">
                                        <div data-fslightbox="gallery1" className="rounded-4" target="_blank" data-type="image">
                                            <img
                                                // style={{ width: '400px', height: '50vh', margin: 'auto' }}
                                                className="rounded-4 fit main-image"
                                                src={mainImage}
                                                alt="Gallery"
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center mb-3">
                                        {/* Thumbnail 1 */}
                                        <div 
                                            data-fslightbox="gallery1" 
                                            className="border mx-1 rounded-2" 
                                            target="_blank" 
                                            data-type="image"
                                        >
                                            <img
                                                width="60"
                                                height="60"
                                                className="rounded-2"
                                                src={thumbnail1}
                                                alt="Thumbnail 1"
                                                onClick={() => handleMainImageClick(thumbnail1)} // Click changes main image
                                            />
                                        </div>

                                        {/* Thumbnail 2 */}
                                        <div 
                                            data-fslightbox="gallery1" 
                                            className="border mx-1 rounded-2" 
                                            target="_blank" 
                                            data-type="image"
                                        >
                                            <img
                                                width="60"
                                                height="60"
                                                className="rounded-2"
                                                src={thumbnail2}
                                                alt="Thumbnail 2"
                                                onClick={() => handleMainImageClick(thumbnail2)} // Click changes main image
                                            />
                                        </div>
                                    </div>
                                </aside>
                                <main className="col-lg-6">
                                    <div className="ps-lg-3">
                                        <button className="close-btn" onClick={onClose} style={{ color: '#D9A04C', position: 'absolute', top: '10px', right: '10px', backgroundColor: 'transparent', border: 'none', cursor: 'pointer', fontSize: '1.5rem' }}>X</button>
                                        <h4 className="title text-dark">
                                            {heading}<br />
                                        </h4>
                                        <h5 className="d-flex flex-row my-3">
                                            <h5 className="text-warning mb-1 me-2">
                                                <FontAwesomeIcon icon={faStar} />
                                                <FontAwesomeIcon icon={faStar} />
                                                <FontAwesomeIcon icon={faStar} />
                                                <FontAwesomeIcon icon={faStar} />
                                                <FontAwesomeIcon icon={faStarHalfAlt} />
                                                <span className="ms-1">4.5</span>
                                            </h5>
                                            <span className="text-success ms-2">In stock</span>
                                        </h5>

                                        {/* <h6 style={{ textAlign: "left" }}>
                                            Diamond bangles, exquisite and timeless, adorn wrists with elegance and brilliance. Crafted with precision, each diamond reflects light, captivating onlookers with its shimmering allure. These luxurious accessories elevate any attire, adding sophistication and glamour to every occasion.
                                        </h6> */}

                                        <hr />

                                        <h5 className="row" style={{ textAlign: "left" }}>
                                            <dt className="col-8">TYPE</dt>
                                            <dd className="col-3">: {type}</dd>
                                        </h5>

                                        <h5 className="row" style={{ textAlign: "left" }}>
                                            <dt className="col-8" style={{ textAlign: "left" }}>CARAT RATE</dt>
                                            <dd className="col-3">: {caratRate}</dd>
                                        </h5>

                                        <h5 className="row" style={{ textAlign: "left" }}>
                                            <dt className="col-8">PURITY</dt>
                                            <dd className="col-3">: {purity}</dd>
                                        </h5>

                                        <h5 className="row" style={{ textAlign: "left" }}>
                                            <dt className="col-8">GOLD WEIGHT</dt>
                                            <dd className="col-3">: {goldWeight}</dd>
                                        </h5>
                                    </div>
                                </main>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default ProductDetail;
